<template>
  <div>
    <table>
      <tr v-for="(item, i) in memberBiz" :key="i">
        <td width="35%">
          <div class="flex-g" @click="setNoteName(item.id)" style="cursor: pointer;">
            <img :src="item.user.avatarUrl" class="imgwrap" alt />
            <div style="margin-left: 10px;text-align:left">
              <div style="font-weight:bold">
                {{ item.user.nickName }}
                <span v-if="item.noteName">({{item.noteName}})</span>
                <span v-else style="color: #999;font-weight:initial">(备注名)</span>
              </div>
              <div>{{item.user.regphoneUser}}</div>
            </div>
          </div>
        </td>
        <td width="35%" style="cursor: pointer;">
          <span>当前授权：</span>
          <span
            v-if="item.membType < 2"
            @click="setLoginPermission(2,item.id)"
            style="color:mediumseagreen"
          >允许登录</span>
          <span v-else @click="setLoginPermission(1,item.id)" style="color: red;">禁止登录</span>
        </td>
        <td style="text-align: right;">
          <el-button @click="deleteMemb(item)" type="danger" size="mini" plain>删除</el-button>
        </td>
      </tr>
    </table>
    <div class="center-g">
      <vue-qr ref="Qrcode" :text="dataObj.text" qid="testQrId"></vue-qr>
    </div>
    <div style="padding: 0px 120px">
      <p v-if="type=='sight'">微信扫描该二维码，即可成为成员。成员可扫码确认用户订单。授权后可手机端登录管理账户。成员无钱包提现和公司信息的操作权限。</p>
      <p v-else>微信扫描该二维码，即可成为成员。成员可在手机端发送优惠券或充值卡。成员获得登录授权后，无账户管理和成员管理的权限。</p>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import { getMembListBiz, updateWithDelteBiz } from "@/api/api";
export default {
  props: ["type"],
  data() {
    return {
      show: false,
      input: "",
      noteName: "",
      status: 0,
      biz: "",
      rsp: null,
      memberBiz: [],
      dataObj: {
        text: "",
        logo: require("../assets/logo.png")
      }
    };
  },
  components: { VueQr },
  mounted() {
    this.biz = this.$store.getters.getBiz;
    this.getMembListBizFn();
    if (this.type == 0) {
      this.dataObj.text =
        "https://server.solar960.com/qrtomini/addmember/" + this.biz.id;
    } else {
      this.dataObj.text =
        "https://server.solar960.com/qrtowechat/addmember/" + this.biz.id;
    }
  },
  methods: {
    getMembListBizFn() {
      const that = this;
      getMembListBiz().then(res => {
        if (res.result) {
          that.memberBiz = res.data;
        }
      });
    },

    
    deleteMemb(row) {
      const that = this;
      this.$confirm("确定删除该成员？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error"
      })
        .then(() => {
          updateWithDelteBiz({
            status: 1,
            id: row.id
          }).then(res => {
            if (res.result) {
              that.$message.success('删除成功');
              that.getMembListBizFn();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "已取消删除"
          });
        });
    },


    setLoginPermission(i, id) {
      const that = this;
      let text = "设置为禁止登录账户？";
      if (i == 1) {
        text = "设置为允许登录账户？";
      }
      this.$confirm(text, {
        type: "warning"
      }).then(() => {
        updateWithDelteBiz({
          membType: i,
          status: 0,
          id: id
        }).then(res => {
          if (res.result) {
            that.$message.success('设置成功');
            that.getMembListBizFn();
          }
        });
      });
    },
    setNoteName(id) {
      const that = this;
      this.$prompt("请输入备注名", {
        center: true,
        inputValidator: this.inputValidator,
        inputErrorMessage: "备注名不超过6字"
      })
        .then(({ value }) => {
          updateWithDelteBiz({
            noteName: value,
            status: 0,
            id: id
          }).then(res => {
            if (res.result) {
              that.$message.success('设置成功');
              that.getMembListBizFn();
            } else {
              this.$alert(res.message, {
                type: "warning",
                callBack: () => {}
              });
            }
          });
        })
        .catch(() => {});
    },
    inputValidator(value) {
      if (value && value.length > 6) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
}
table td {
  padding: 10px 0px;
  border-bottom: 1px solid #eee;
  text-align: center;
}
.imgwrap {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}
</style>