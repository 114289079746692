<template>
  <div>
    <el-row :gutter="120">
      <el-col :span="12">
        <h3>公司信息</h3>
        <table>
          <tr>
            <td width="100px">ID</td>
            <td>{{sightCompany.id}}</td>
            <td>
              <i class="el-icon-lock"></i>
            </td>
          </tr>
          <tr>
            <td>名称</td>
            <td>{{sightCompany.name}}</td>
            <td>
              <i class="el-icon-lock"></i>
            </td>
          </tr>
          <tr>
            <td>统一代码</td>
            <td>{{sightCompany.companyCode}}</td>
            <td>
              <i class="el-icon-lock"></i>
            </td>
          </tr>
          <tr>
            <td>简称</td>
            <td>{{sightCompany.noteNameBiz}}</td>
            <td>
              <i class="el-icon-lock"></i>
            </td>
          </tr>
          <tr>
            <td>服务费</td>
            <td>6%</td>
            <td>
              <i class="el-icon-lock"></i>
            </td>
          </tr>
          <tr>
            <td>提现周期</td>
            <td>微信订单：1天 / 抖音快手订单：4天 </td>
            <td>
              <i class="el-icon-lock"></i>
            </td>
          </tr>
          <tr>
            <td>联系人</td>
            <td>{{sightCompany.contactPerson}}</td>
            <td>
              <i class="el-icon-edit-outline" @click="setContact"></i>
            </td>
          </tr>
          <tr>
            <td>注册手机</td>
            <td>{{sightCompany.regphoneUser}}</td>
            <td>
              <i class="el-icon-edit-outline" @click="changePhone"></i>
            </td>
          </tr>
          <tr>
            <td>收款人</td>
            <td>{{sightCompany.casherName}} （{{sightCompany.casherPhone}}）</td>
            <td>
              <i class="el-icon-edit-outline" @click="changeCasher"></i>
            </td>
          </tr>
          <tr>
            <td>公司地址</td>
            <td>{{sightCompany.province}}{{sightCompany.city}}{{sightCompany.district}}{{sightCompany.address}}</td>
            <td>
              <i class="el-icon-edit-outline" @click="getCityListFn"></i>
            </td>
          </tr>
          <tr>
            <td>协议</td>
            <td>
              <el-button type="text" @click="toContent">查看</el-button>
            </td>
          </tr>
        </table>
      </el-col>
      <el-col :span="12">
        <h3>成员管理</h3>
        <members type="sight" />
      </el-col>
    </el-row>
    <!-- 修改通讯地址 -->
    <el-dialog :visible.sync="addressShow" width="50%" :close-on-click-modal="false" top="2vh">
      <div style="font-size: 18px; margin:20px;text-align:center">修改通讯地址</div>
      <el-form label-width="160px" style="margin: 30px; width: 50%">
        <el-form-item label="省市区">
          <el-cascader
            v-model="value"
            :options="cities"
            @change="handleChange"
            size="small"
            style="width:400px"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="cityData.address" placeholder="请输入详细地址，必填" style="width:400px ;"></el-input>
        </el-form-item>
      </el-form>
      <div class="center-g">
        <el-button type="primary" @click="sunbmitAddress">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCompanyBasicPc, updateBizInfo, bizGetCityList } from "@/api/api";
import members from "../components/Members.vue";
export default {
  components: {
    members
  },
  data() {
    return {
      sightCompany: {},
      members: [],
      activeName: "0",
      agreement: "",
      agreementShow: false,
      addressShow: false,
      value: [],
      cities: [],
      cityData: {}
    };
  },
  mounted() {
    if (this.$store.getters.getBiz.membType > 0) {
      this.$router.push({ name: "login" });
      localStorage.setItem("Authorization", "");
    } else {
      this.getCompanyInfoFn();
    }
  },
  methods: {
    getCompanyInfoFn() {
      const that = this;
      getCompanyBasicPc().then(res => {
        if (res.result) {
          that.sightCompany = res.data;
        }
      });
    },
    changePhone() {
      this.$confirm("确认更改管理员手机？", {
        type: "warning"
      })
        .then(() => {
          this.$router.push({
            path: "/sightcompany/validphonesightcompany",
            query: { type: "setphone" }
          });
        })
        .catch(() => {});
    },
    changeCasher() {
      this.$confirm("确认更改收款微信？", {
        type: "warning"
      })
        .then(() => {
          this.$router.push({
            path: "/sightcompany/validphonesightcompany",
            query: { type: "setcasher" }
          });
        })
        .catch(() => {});
    },
    setContact() {
      const that = this;
      that
        .$prompt("请输入联系人", {
          inputPattern: /^\S{2,6}$/,
          inputErrorMessage: "联系人姓名在2~6字之间"
        })
        .then(({ value }) => {
          updateBizInfo({
            contactPerson: value
          }).then(res => {
            if (res.result) {
              that.$message.success('设置成功');
              that.sightCompany.contactPerson = value;
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "取消输入"
          });
        });
    },
    getCityListFn() {
      const that = this;
      that.cityData = { ...that.sightCompany };
      if (that.cities.length == 0) {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        bizGetCityList({
          type: "district"
        }).then(res => {
          that.cities = res.data;
          that.addressShow = true;
          loading.close();
          let provinceIndex = res.data.findIndex(item => {
            return (
              item.adcode.toString().slice(0, 2) ==
              that.cityData.adcode.toString().slice(0, 2)
            );
          });
          let cityIndex = res.data[provinceIndex].children.findIndex(item => {
            return (
              item.adcode.toString().slice(0, 4) ==
              that.cityData.adcode.toString().slice(0, 4)
            );
          });
          that.value = [
            res.data[provinceIndex].value,
            res.data[provinceIndex].children[cityIndex].value,
            that.cityData.adcode
          ];
        });
      } else {
        that.addressShow = true;
      }
    },

    handleChange(value) {
      let provinceIndex = this.cities.findIndex(item => {
        return item.adcode == value[0];
      });

      this.cityData.province = this.cities[provinceIndex].name;
      let cityIndex = this.cities[provinceIndex].children.findIndex(item => {
        return item.adcode == value[1];
      });
      this.cityData.city = this.cities[provinceIndex].children[cityIndex].name;
      let districtIndex = this.cities[provinceIndex].children[
        cityIndex
      ].children.findIndex(item => {
        return item.adcode == value[2];
      });
      this.cityData.district = this.cities[provinceIndex].children[
        cityIndex
      ].children[districtIndex].name;
      this.cityData.adcode = value[2];
    },
    sunbmitAddress() {
      const that = this;
      if (this.cityData.address == "") {
        this.$alert("地址不能为空", {
          type: "error",
          callback: () => {}
        });
      } else {
        updateBizInfo(that.cityData).then(res => {
          if (res.result) {
            that.sightCompany = that.cityData;
            that.addressShow = false;
            that.$message({
              message: "修改成功",
              type: "success"
            });
          }
        });
      }
    },
    toContent() {
      let routeUrl = this.$router.resolve({
        path: "/content",
        query: { type: "agreementSight" }
      });
      window.open(routeUrl.href, "_blank");
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
table td {
  padding: 10px 0;
}

h3 {
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px dashed #aaa;
  padding-bottom: 10px;
}
</style>